import React from "react";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import {
  Alert,
  Button,
  Stack,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { useState } from "react";
import { updateSchema as validationSchema } from "utils/validation-schema";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showEdit, showManageTiers, showUserManagament } from "state/dashboard";
import { data } from "jquery";
import CorrectAnimation from "components/lottieAnimations/CorrectAnimation";
import CorrectAnimationReceipt from "components/lottieAnimations/CorrectAnimationReceipt";
import { useLocation, useNavigate } from "react-router-dom";
import { updateAdminUrl } from "utils/constants";

const UpdateAdmin = ({setViewState }) => {
  useMediaQuery("(min-width: 600px)");
  const admin = useSelector((state) => state.dashboard).admin;
  // const location = useLocation();
  const { id, email, name} = admin;

  const [data, setData] = useState(null);
  const dispatcch = useDispatch();

  const handleAddAdminClick = () => {
    setViewState("admins");
  };

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: name,
      email: email,
      password: "",
      password_confirmation: "",
      submit: null
    },
    validationSchema: validationSchema,

    onSubmit: async (values, helpers) => {
      values.id = id
      try {

        axios
          .post(updateAdminUrl, values, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {

            if (response.status === 200) {
              setData(response.data.data)
            }
          })
          .catch((error) => {
            console.log(error)
            if (error.response.data.errors){
              console.log(error.response.data.errors)
              const validationErrors = error.response.data.errors;
              helpers.setErrors(validationErrors);
            } else if (error.response.data.message == "This email already taken"){
              helpers.setFieldError("email" , "This email already taken")
            } else {
              helpers.setFieldError("submit" , "Something went wrong please try again later")
            }
            
          });
      } catch (err) {
        console.log(err)
        
      }
    },
  });

  return (
    <>
      
      {data && 
        <CorrectAnimationReceipt onComplete={()=>{
            dispatcch(showUserManagament())
        }}></CorrectAnimationReceipt>
      }
      <div>
        <Box
          sx={{
            backgroundColor: "#e1e1e1",
            float: "right",
            width: "82.5%",
            flex: "1 1 auto",
            height:"120%",
            alignItems: "start",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor:"white",
              maxWidth: "55rem",
              padding:"7rem",
              marginTop:"4rem",
              marginBottom:'4rem',
              // py: "7rem",
              width: "100%",
              height:"120%",
              borderRadius:"2rem"
            }}
          >
            <div>
              <Stack
                spacing={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h4" sx={{marginBottom:'2rem' , marginLeft:"2rem"}}>Update Admin</Typography>
                    
              </Stack>
              {}
              {
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Stack spacing={2}>

                    <div style={{ display: "flex", gap: "1rem" }}>

                    <TextField
                      fullWidth
                      label="ID"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={id}
                      disabled
                    />

                      <TextField
                        error={!!(formik.touched.name && formik.errors.name)}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        label="Name"
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                      />
                    </div>
                    
                    <div style={{ display: "flex", gap: "1rem" }}>

                    <TextField
                      error={!!(formik.touched.email && formik.errors.email)}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      label="Email Address"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      value={formik.values.email}
                    />
                  </div>

                  <div style={{ display: "flex", gap: "1rem" }}>

                    <TextField
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      label="Password"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.password}
                    />

                    <TextField
                      error={
                        !!(formik.touched.password_confirmation && formik.errors.password_confirmation)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password_confirmation && formik.errors.password_confirmation
                      }
                      label="Password Confirmation"
                      name="password_confirmation"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.password_confirmation}
                    />

                  </div>
                    <Divider></Divider>
                   
                  </Stack>

                  {formik.errors.submit && (
                    <Typography color="error" sx={{ mt: 3 }} variant="body2">
                      {formik.errors.submit}
                    </Typography>
                  )}
                  <Button
                    fullWidth
                    size="large"
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                  >
                    Update
                  </Button>

                  <Alert color="primary" severity="info" sx={{ mt: 3 }}></Alert>
                </form>
              }
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default UpdateAdmin;
