import correctLottie from '../../assets/img/correctLottie.json'
import Lottie from 'lottie-react'
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';

export default function CorrectAnimationReceipt({ onComplete }) {
  const navigate = useNavigate();

  return (
    <Dialog
      dir='rtl'
      disableBackdropClick
      disableEscapeKeyDown
      open={true}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow:"hidden"
        },
      }}
    >
      <Lottie
        animationData={correctLottie}
        loop={false}
        onComplete={() => {
          setTimeout(() => {
            onComplete()
          }, 1500);
        }}
      >
      </Lottie>
    </Dialog>
  );
}