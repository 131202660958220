import React, { useState } from "react";
import {
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Notifications from "@mui/icons-material/Notifications";

const NotificationIcon = ({ notifications }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    const theme = useTheme();

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

    const maxHeight = (lgUp) ? "50rem" : "40rem"

    return (
      <>
        <IconButton style={{ backgroundColor: "white" }} onClick={handleClick}>
          <Badge badgeContent={notifications.length} color="primary">
            <Notifications />
          </Badge>
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ maxHeight: maxHeight , zIndex: 9999, position: "fixed", marginTop: "-1rem" }}
        >
          <div style={{ width: "25rem", overflow: "auto" }}>
            <List>
              {notifications.map((notification) => (
                <ListItem key={notification.id}>
                  <ListItemText
                    primary={notification.id + ". " + notification.body}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Popover>
        {open && <div className="overlay" onClick={handleClose} />}
      </>
    );
  };
  
  export default NotificationIcon;