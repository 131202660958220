import PropTypes from "prop-types";
import { useState } from "react";
import { Box, ButtonBase, Collapse } from "@mui/material";
import { useDispatch } from "react-redux";
import { showOverview, showForms, showFill, showContact, showUserManagement, showUserManagament } from "state/dashboard";

export const SideNavItem = (props) => {
  const {
    active = false,
    disabled,
    icon,
    title,
    path,
    children,
    nested,
  } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    if (path === "/") {
      dispatch(showOverview());
    } else if (path === "/form" && children) {
      handleOpen();
    } else if (path === "/submitted-forms") {
      dispatch(showForms())
    } else if (path === "/user-management") {
      dispatch(showUserManagament());
    } else if (path === "/forms") {
      dispatch(showFill());
    }  else if (path === "/contact-us") {
      dispatch(showContact());
    } else {
      // dispatch action for other paths
    }
  };

  return (
    <>
      <li>
        <ButtonBase
          onClick={handleClick}
          sx={{
            alignItems: "center",
            borderRadius: 1,
            display: "flex",
            justifyContent: "flex-start",
            color: "#333",
            pl: "16px",
            pr: "16px",
            py: "12px",
            textAlign: "left",
            width: "100%",
            ...(active && {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            }),
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.09)",
            },
          }}
        >
          {icon && (
            <Box
              sx={{
                minWidth: 20,
                mr: 1,
              }}
            >
              {icon}
            </Box>
          )}
          {title}
        </ButtonBase>
        {children && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ pl: nested ? 4 : 0 }}>
              {children.map((child) => (
                <SideNavItem
                  key={child.title}
                  title={child.title}
                  path={child.path}
                  icon={child.icon}
                  nested={true}
                />
              ))}
            </Box>
          </Collapse>
        )}
      </li>
    </>
  );
};

SideNavItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.element,
    })
  ),
  nested: PropTypes.bool,
};
