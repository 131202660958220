import { Box, Container, Unstable_Grid2 as Grid, useMediaQuery, useTheme } from "@mui/material";
import { OverviewSales } from "./forms-pre-month";
import { OverviewTasksProgress } from "./forms-submitted";
import { OverviewTotalCustomers } from "./users";
import { OverviewTraffic } from "./overview-traffic";
import { getStatsUrl } from "utils/constants";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingAnimation from "components/lottieAnimations/loadingAnimation";
import NotificationIcon from "./notification";
const Overview = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const theme = useTheme();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    axios
      .get(getStatsUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);

        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (
          err.response.data.message === "Invalid token" ||
          err.response.data.message === "Unauthenticated."
        ) {
          sessionStorage.clear("token");
          navigate("/login");
        }
        setData(err);
      });
  }, []);


  if (!data) {
    return <LoadingAnimation animationNumber={0} />;
  }
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "#e1e1e1",
          width: "82.5%",
          float: "right",
          py: 2.5,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            
          {lgUp ? (
                    <>
                        <Grid xs={12} sm={12} lg={4}>
                          <OverviewTotalCustomers
                            difference={16}
                            positive={false}
                            sx={{ height: "100%" }}
                            value={data.adminCount}
                          />
                        </Grid>
                        <Grid xs={12} sm={12} lg={4}>
                          <OverviewTasksProgress
                            sx={{ height: "100%" }}
                            value={data.formsCount}
                          />
                        </Grid>

                        <Grid xs={12} lg={4} padding={"1.5rem"}  style={{ height:"6rem",display: 'flex', justifyContent: 'flex-end' }}>
                          <NotificationIcon notifications={data.notifications} />
                        </Grid>
                    </>
                    ) : (
                      <>
                      
                      <Grid xs={12} sm={12} lg={4} padding={"1.5rem"}  style={{ height:"6rem",display: 'flex', justifyContent: 'flex-end' }}>
                        <NotificationIcon notifications={data.notifications} />
                      </Grid>

                      <Grid xs={12} sm={12} lg={4}>
                        <OverviewTotalCustomers
                          difference={16}
                          positive={false}
                          sx={{ height: "100%" }}
                          value={data.adminCount}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} lg={4}>
                        <OverviewTasksProgress
                          sx={{ height: "100%" }}
                          value={data.formsCount}
                        />
                      </Grid>
                      
                      </>
                    )}

           

            <Grid xs={12} sm={12} lg={8}>
              <OverviewSales
                title={"Forms per month"}
                chartSeries={[
                  {
                    name: "2023",
                    data: data.formsPerMonth,
                  },
                ]}
                sx={{ height: "100%" }}
              />
            </Grid>

            <Grid xs={12} sm={12} md={6} lg={4}>
              <OverviewTraffic
                title="Risk Categories"
                chartSeries={[
                  data.risk.low ?? 0,
                  data.risk.intermediate ?? 0,
                  data.risk.high ?? 0,
                  data.risk.danger ?? 0,
                ]}
                labels={["Low", "Borderline", "Intermediate", "High"]}
                sx={{ height: "100%" }}
              />
            </Grid>

            <Grid xs={12} md={12} lg={8}>
              {
                <OverviewSales
                  title={"Healthcare Setting"}
                  flag={1}
                  chartSeries={[
                    {
                      name: "Healthcare Setting",
                      data: [
                        data.statistics.healthcare_setting["MOH"] ?? 0,
                        data.statistics.healthcare_setting[
                          "Ministry of Defense and Aviation Medical Services"
                        ] ?? 0,
                        data.statistics.healthcare_setting[
                          "Ministry of Interior Medical Services"
                        ] ?? 0,
                        data.statistics.healthcare_setting[
                          "National Guard Medical Health Affairs"
                        ] ?? 0,
                        data.statistics.healthcare_setting[
                          "University hospitals"
                        ] ?? 0,
                        data.statistics.healthcare_setting["KFSH & RC"] ?? 0,
                        data.statistics.healthcare_setting[
                          "private healthcare sector"
                        ] ?? 0,
                        data.statistics.healthcare_setting["others"] ?? 0,
                      ],
                    },
                  ]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <OverviewTraffic
                title="Smoking"
                chartSeries={[
                  data.statistics.smoker["Current"] ?? 0,
                  data.statistics.smoker["Never"] ?? 0,
                  data.statistics.smoker["Former"] ?? 0,
                ]}
                labels={["Current", "Never", "Former"]}
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <OverviewTraffic
                title="Gender"
                chartSeries={[
                  data.statistics.gender["Male"] ?? 0,
                  data.statistics.gender["Female"] ?? 0,
                ]}
                labels={["Male", "Female"]}
                sx={{ height: "100%" }}
              />
            </Grid>

            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Elevated Coronary Artery Calcium"
                  chartSeries={[
                    data.statistics.elevatedCoronaryArteryCalciumScore["Yes"] ??
                      0,
                    data.statistics.elevatedCoronaryArteryCalciumScore["No"] ??
                      0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Chronic Inflammatory Condition"
                  chartSeries={[
                    data.statistics.chronicInflammatoryCondition["Yes"] ?? 0,
                    data.statistics.chronicInflammatoryCondition["No"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Premature Menopause History"
                  chartSeries={[
                    data.statistics.historyOfPrematureMenopause["Yes"] ?? 0,
                    data.statistics.historyOfPrematureMenopause["No"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Elevated High Sensitivity"
                  chartSeries={[
                    data.statistics.elevatedHighSensitivity["Yes"] ?? 0,
                    data.statistics.elevatedHighSensitivity["No"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Metabolic Syndrome"
                  chartSeries={[
                    data.statistics.metabolicSyndrome["Yes"] ?? 0,
                    data.statistics.metabolicSyndrome["No"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Familial Hypercholesterolemia"
                  chartSeries={[
                    data.statistics.familialHypercholesterolemia["Yes"] ?? 0,
                    data.statistics.familialHypercholesterolemia["No"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Family History"
                  chartSeries={[
                    data.statistics.familyHistory["Yes"] ?? 0,
                    data.statistics.familyHistory["No"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Chronic Kidney Disease"
                  chartSeries={[
                    data.statistics.chronicKidneyDisease["Yes"] ?? 0,
                    data.statistics.chronicKidneyDisease["No"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Diabetes Mellitus With End Organ Damage"
                  chartSeries={[
                    data.statistics.diabetesMellitusWithEndOrganDamage["1"] ??
                      0,
                    data.statistics.diabetesMellitusWithEndOrganDamage["0"] ??
                      0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Diabetes History"
                  chartSeries={[
                    data.statistics.historyOfDiabetes["1"] ?? 0,
                    data.statistics.historyOfDiabetes["0"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="Aspirin Therapy"
                  chartSeries={[
                    data.statistics.onAspirinTherapy["1"] ?? 0,
                    data.statistics.onAspirinTherapy["0"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="On Statin"
                  chartSeries={[
                    data.statistics.onStatin["1"] ?? 0,
                    data.statistics.onStatin["0"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              {
                <OverviewTraffic
                  title="On Hypertension Treatment"
                  chartSeries={[
                    data.statistics.onHypertensiontreatment["1"] ?? 0,
                    data.statistics.onHypertensiontreatment["0"] ?? 0,
                  ]}
                  labels={["Yes", "No"]}
                  sx={{ height: "100%" }}
                />
              }
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Overview;
