import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,

  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Layout as AuthLayout } from "./layout";
import { useNavigate } from "react-router-dom";

import {  Button, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { loginUrl } from "utils/constants";

const Login = () => {
  const theme = useTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  

  const navigate = useNavigate();
  //  const router = useRouter();

  const signIn = async (uid, password, helpers) => {
    setIsSubmitting(true)
    console.log(uid);
    console.log(password);
    fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept":"application/json"
      },
      body: JSON.stringify({
        email: uid,
        password: password,
      }),
    })
      .then((response) => {
        setIsSubmitting(false)
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then((data) => {
        sessionStorage.setItem("token", data.data.token);
        console.log(data.data.token)
        setIsLoggedIn(true);
        navigate("/dashboard");
      })
      .catch((error) => {
        setIsSubmitting(false)
        error.then((errorMessage) => {
          console.log(errorMessage)
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: errorMessage.message });
          
          helpers.setSubmitting(false);
        });
      })
      .catch(() => {
        setIsSubmitting(false)
        helpers.setErrors({
          submit: "An error occurred. Please try again later.",
        });
        helpers.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      uid: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      uid: Yup.string()
        .email("Invalid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),

    onSubmit: async (values, helpers) => {
      try {
        await signIn(values.uid, values.password, helpers);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      {" "}
      <AuthLayout>
        <Box
          className="login"
          mt={20}
          sx={{
            backgroundColor: "background.paper",
            flex: "2 2 auto",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          
          <Box
            sx={{
              maxWidth: 550,
              px: 3,
              pb: "8rem",
              width: "100%",
            }}
          >

            <div>
            <img src="../../img/app-logo.png" alt="Logo" width="100%" style={{ marginTop: "-5rem" , marginBottom:"3rem" }} />              
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Typography variant="h4">Login</Typography>
              </Stack>

              {!isLoggedIn && (
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      error={!!(formik.touched.uid && formik.errors.uid)}
                      helperText={formik.touched.uid && formik.errors.uid}
                      value={formik.values.uid}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      label="E-mail"
                      name="uid"
                      type="email"
                    />

                    <TextField
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      label="Password"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="password"
                      value={formik.values.password}
                    />

                    {isSubmitting ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                      </Box>
                    ) : (
                      <Button
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }}
                      type="submit"
                    >
                      Login
                    </Button>
                    )}

                   
                  </Stack>

                  {formik.errors.submit && (
                    <Typography color="error" sx={{ mt: 3 }} variant="body2">
                      {formik.errors.submit}
                    </Typography>
                  )}
                </form>
              )}
            </div>
          </Box>
        </Box>
      </AuthLayout>
    </>
  );
};

export default Login;
