import loadingLottie from '../../assets/img/loadingLottie.json'
import loading3 from '../../assets/img/loading3.json'
import Lottie from 'lottie-react'
import Dialog from '@mui/material/Dialog';

export default function LoadingAnimation(props){

    const {animationNumber , theClass} = props

    return <Dialog dir='rtl' 
    disableBackdropClick 
    disableEscapeKeyDown 
    open={true}
    className = {theClass}
    PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
    }} >

      {animationNumber == 1 ? <Lottie animationData={loadingLottie} ></Lottie> :  <Lottie animationData={loading3} ></Lottie>}
        
     
      

</Dialog>
}