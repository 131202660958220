const initialState = {
    lang: "en",
  };
  
  export default function LanguageReducer(state = initialState, action) {
    switch (action.type) {
      case "en":
        return { ...state, lang: "en" };
  
        case "ar":
          return { ...state, lang: "ar" };
    
      default:
        return state;
    }
  }
  
  // actions.js
  export const displayAR = () => ({ type: "ar" });
  export const displayEN = () => ({ type: "en" });