import CorrectAnimationReceipt from "components/lottieAnimations/CorrectAnimationReceipt";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  useMediaQuery,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Unstable_Grid2 as Grid,
  Typography,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  FormHelperText,
  Tooltip,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Nav from "./Nav";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { Info } from "@mui/icons-material";
import { clientRecordUrl } from "utils/constants";
import Contact from "scenes/dashboard/contact-us/contact-us";
import { styled } from "@mui/material/styles";
import { ContactMail } from "@mui/icons-material";

export const KFCForm = (props) => {
  const admin = props["admin"];

  const navigate = useNavigate();
  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [showAnimation, setShowAnimation] = useState(null);
  const [showResult, setShowResult] = useState(true);

  const [gender, setGender] = useState(null);
  const [isHealthcarePractitioner, setIsHealthcarePractitioner] =
    useState(null);
  const [healthcare_setting, setHealthcare_setting] = useState(null);
  const [smoker, setSmoker] = useState(null);
  const [onHypertensiontreatment, setOnHypertensiontreatment] = useState(null);
  const [onStatin, setOnStatin] = useState(null);
  const [onAspirinTherapy, setOnAspirinTherapy] = useState(null);
  const [historyOfDiabetes, setHistoryOfDiabetes] = useState(null);
  const [chronicKidneyDisease, setChronicKidneyDisease] = useState(null);
  const [
    diabetesMellitusWithEndOrganDamage,
    setDiabetesMellitusWithEndOrganDamage,
  ] = useState(null);
  const [familyHistory, setFamilyHistory] = useState(null);
  const [familialHypercholesterolemia, setFamilialHypercholesterolemia] =
    useState(null);
  const [metabolicSyndrome, setMetabolicSyndrome] = useState(null);
  const [elevatedHighSensitivity, setElevatedHighSensitivity] = useState(null);
  const [historyOfPrematureMenopause, setHistoryOfPrematureMenopause] =
    useState(null);
  const [chronicInflammatoryCondition, setChronicInflammatoryCondition] =
    useState(null);
  const [
    elevatedCoronaryArteryCalciumScore,
    setElevatedCoronaryArteryCalciumScore,
  ] = useState(null);

  const StyledTypography = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "1rem",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
      textDecoration: "none",
      "& .MuiSvgIcon-root": {
        transform: "rotate(360deg)",
        transition: "transform 0.5s ease-in-out",
      },
    },
    "& .MuiSvgIcon-root": {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: "0.5rem",
      transition: "transform 0.5s ease-in-out",
    },
  }));

  const handleChangeisHealthcarePractitioner = (event) => {
    setIsHealthcarePractitioner(event.target.value);
  };

  const handleChangeSmoker = (event) => {
    setSmoker(event.target.value);
  };

  const handleChangeOnHypertensiontreatment = (event) => {
    setOnHypertensiontreatment(event.target.value);
  };

  const handleChangeOnStatin = (event) => {
    setOnStatin(event.target.value);
  };

  const handleChangeOnAspirinTherapy = (event) => {
    setOnAspirinTherapy(event.target.value);
  };

  const handleChangeHistoryOfDiabetes = (event) => {
    setHistoryOfDiabetes(event.target.value);
  };

  const handleChangeDiabetesMellitusWithEndOrganDamage = (event) => {
    setDiabetesMellitusWithEndOrganDamage(event.target.value);
  };

  const handleChangeChronicKidneyDisease = (event) => {
    setChronicKidneyDisease(event.target.value);
  };

  const handleChangeFamilyHistory = (event) => {
    setFamilyHistory(event.target.value);
  };

  const handleChangeFamilialHypercholesterolemia = (event) => {
    setFamilialHypercholesterolemia(event.target.value);
  };

  const handleChangeMetabolicSyndrome = (event) => {
    setMetabolicSyndrome(event.target.value);
  };
  const handleChangeElevatedHighSensitivity = (event) => {
    setElevatedHighSensitivity(event.target.value);
  };
  const handleChangeHistoryOfPrematureMenopause = (event) => {
    setHistoryOfPrematureMenopause(event.target.value);
  };
  const handleChangeChronicInflammatoryCondition = (event) => {
    setChronicInflammatoryCondition(event.target.value);
  };
  const handleChangeElevatedCoronaryArteryCalciumScore = (event) => {
    setElevatedCoronaryArteryCalciumScore(event.target.value);
  };

  const states = [
    { value: "MOH", label: "MOH" },
    {
      value: "Ministry of Defense Health Services",
      label: "Ministry of Defense Health Services",
    },
    {
      value: "Ministry of Interior Medical Services",
      label: "Ministry of Interior Medical Services",
    },
    {
      value: "Ministry of National Guard Health Affairs",
      label: "Ministry of National Guard Health Affairs",
    },
    { value: "University hospitals", label: "University hospitals" },
    { value: "KFSH & RC", label: "KFSH & RC" },
    { value: "private healthcare sector", label: "private healthcare sector" },
    { value: "others", label: "others" },
  ];

  const regions = [
    { value: "Mecca Region", label: "Mecca Region" },
    { value: "Riyadh Region", label: "Riyadh Region" },
    { value: "Eastern Region", label: "Eastern Region" },
    { value: "Aseer Region", label: "Aseer Region" },
    { value: "Jazan Region", label: "Jazan Region" },
    { value: "Medina Region", label: "Medina Region" },
    { value: "Al-Qassim Region", label: "Al-Qassim Region" },
    { value: "Tabuk Region", label: "Tabuk Region" },
    { value: "Hail Region", label: "Ha`il Region" },
    { value: "Najran Region", label: "Najran Region" },
    { value: "Al-Jawf Region", label: "Al-Jawf Region" },
    { value: "Al-Bahah Region", label: "Al-Bahah Region" },
    { value: "Northern Borders Region", label: "Northern Borders Region" },
  ];

  const submitForm = async (values, helpers) => {
    setLoading(true);

    try {
      axios
        .post(clientRecordUrl, values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          setLoading(false);
          if (response.status === 201) {
            setdata(response.data);
            setShowAnimation(true);
            setLoading(false);
          } else {
            console.log("fff" + response);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
          const validationErrors = error.response.data.errors;
          helpers.setErrors(validationErrors);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    isHealthcarePractitioner: Yup.number()
      .oneOf([0, 1], "Please enter Yes or No")
      .required("Are you healthcare parctitioner is a required field"),
    healthcare_setting: Yup.string().required(
      "Healthcare Sector is a required field"
    ),
    region: Yup.string().required("Region is a required field"),
    centerName: Yup.string().required("Center name is a required field"),
    age: Yup.number().required("Age is a required field").min(40).max(99),
    systolicBloodPressure: Yup.number()
      .required("Systolic Blood Pressure is a required field")
      .min(90)
      .max(200),
    diastolicBloodPressure: Yup.number()
      .required("Diastolic Blood Pressure is a required field")
      .min(60)
      .max(130),
    smoker: Yup.string()
      .oneOf(
        ["Current", "Former", "Never"],
        "Required to enter just one of choices"
      )
      .required("Smoker is a required field"),
    onHypertensiontreatment: Yup.number()
      .oneOf([0, 1], "Please enter Yes or No")
      .required("On Hypertension Treatment is a required field"),
    onStatin: Yup.number()
      .oneOf([0, 1], "Please enter Yes or No")
      .required("On a Statin is a required field"),
    onAspirinTherapy: Yup.number()
      .oneOf([0, 1], "Please enter Yes or No")
      .required("On Aspirin Therapy is a required field"),
    historyOfDiabetes: Yup.number()
      .oneOf([0, 1], "Please enter Yes or No")
      .required("History of Diabetes is a required field"),
    diabetesMellitusWithEndOrganDamage: Yup.number()
      .oneOf([0, 1], "Please enter Yes or No")
      .required("Diabetes Mellitus with End-organ Damage is a required field"),
    totalCholesterol: Yup.number()
      .required("Total Cholesterol is a required field")
      .min(130)
      .max(320),
    LDL: Yup.number().required("LDL is a required field").min(30).max(300),
    HDL: Yup.number().required("HDL is a required field").min(20).max(100),
    chronicKidneyDisease: Yup.string().required(
      "Chronic Kidney Disease is a required field"
    ),
    familyHistory: Yup.string().required(
      "Family History of Premature Coronary Artery Disease is a required field"
    ),
    familialHypercholesterolemia: Yup.string().required(
      "Familial Hypercholesterolemia is a required field"
    ),
    metabolicSyndrome: Yup.string().required(
      "Metabolic Syndrome is a required field"
    ),
    elevatedHighSensitivity: Yup.string().required(
      "Elevated High-sensitivity C-reactive protein is a required field"
    ),
    historyOfPrematureMenopause: Yup.string().required(
      "History of premature menopause or pregnancy-associated conditions that increase the risk of atherosclerotic cardiovascular diseases is a required field"
    ),
    historyOfPrematureMenopauseSpecification: Yup.string().test(
      "isRequired",
      "Please provide a value for the specification",
      function (value) {
        const { historyOfPrematureMenopause } = this.parent;
        if (historyOfPrematureMenopause === "Yes") {
          return value !== undefined && value.trim() !== "";
        }
        return true;
      }
    ),
    chronicInflammatoryCondition: Yup.string().required(
      "Chronic Inflammatory Condition is a required field"
    ),
    chronicInflammatoryConditionSpecification: Yup.string().test(
      "isRequired",
      "Please provide a value for the specification",
      function (value) {
        const { chronicInflammatoryCondition } = this.parent;
        if (chronicInflammatoryCondition === "Yes") {
          return value !== undefined && value.trim() !== "";
        }
        return true;
      }
    ),
    elevatedCoronaryArteryCalciumScore: Yup.string().required(
      "Elevated Coronary Artery Calcium Score is a required field"
    ),
    coronaryArteryCalciumScore: Yup.number().min(0).max(100),
  });
  const [open2, setOpen2] = React.useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  let initialValues = {
    isHealthcarePractitioner: "",
    healthcare_setting: "",
    centerName: "",
    age: "",
    systolicBloodPressure: "",
    diastolicBloodPressure: "",
    smoker: "",
    onHypertensiontreatment: "",
    onStatin: "",
    onAspirinTherapy: "",
    historyOfDiabetes: "",
    diabetesMellitusWithEndOrganDamage: "",
    totalCholesterol: "",
    LDL: "",
    HDL: "",
    chronicKidneyDisease: "",
    familyHistory: "",
    familialHypercholesterolemia: "",
    metabolicSyndrome: "",
    elevatedHighSensitivity: "",
    historyOfPrematureMenopause: "",
    historyOfPrematureMenopauseSpecification: "",
    chronicInflammatoryCondition: "",
    chronicInflammatoryConditionSpecification: "",
    elevatedCoronaryArteryCalciumScore: "",
    coronaryArteryCalciumScore: "",
    submit: null,
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  const onSubmit = (values, helpers) => {
    submitForm(values, helpers);
  };
  const theme = useTheme();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const margin = lgUp ? "12rem" : "0rem";
  const marginChoice = lgUp ? "0rem" : "0rem";
  const paddingContent = lgUp ? "1.4rem" : "1.3rem";
  const paddingBottom = lgUp ? "1.5rem" : "1,5rem";
  const marginInlineStart = lgUp ? "0.3rem" : "0.3rem";

  const resultPaddingInline = lgUp ? "30rem" : "2rem";
  const resultPaddingTop = lgUp ? "4rem" : "4rem";
  const resultPaddingBottom = lgUp ? "4rem" : "4rem";

  const tipWidth = lgUp ? "1.3rem" : "1.3rem";
  const tipMarginTop = lgUp ? "0.55rem" : "0.55rem";
  const tipMarinInlineStart = lgUp ? "-0.6rem" : "-0.6rem";
  const tipMarginInlineEnd = lgUp ? "1rem" : "1rem";

  return (
    <>
      {admin ? (
        <></>
      ) : (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown
            disableBackdropClick
          >
            <DialogTitle sx={{ textTransform: "none" }}>Be careful</DialogTitle>

            <DialogContent>
              <DialogContentText>
                The tool should be used for primary prevention patients (those
                without ASCVD) only
              </DialogContentText>
            </DialogContent>

            <DialogActions dir="rtl">
              <Button onClick={handleClose}>Okay</Button>
            </DialogActions>
          </Dialog>
          <Nav></Nav>
        </>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(helpers) => (
          <Form>
            {data ? (
              <>
                {showAnimation === true && (
                  <CorrectAnimationReceipt
                    onComplete={() => {
                      setShowAnimation(false);
                    }}
                  ></CorrectAnimationReceipt>
                )}

                {showAnimation === false && (
                  <Box
                    sx={{
                      paddingInline: margin,
                      paddingTop: resultPaddingTop,
                      paddingInline: resultPaddingInline,
                      paddingBottom: resultPaddingBottom,
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <Card>
                      <CardHeader title="The Result" sx={{ pl: "1.5rem" }} />

                      <CardContent sx={{ pt: "0.3rem", pl: "1.5rem" }}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12} md={12}>
                            <Typography sx={{ fontSize: 30 }}>
                              10-year estimated risk for hard ASCVDs
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} pt="1rem">
                          <Typography sx={{ fontWeight: "bold", fontSize: 40 }}>
                            {data.result} %
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          {data.result < 5 && (
                            <Typography
                              color="green"
                              sx={{ fontWeight: "bold" }}
                            >
                              Low-risk
                            </Typography>
                          )}

                          {data.result >= 5 && data.result <= 7.4 && (
                            <Typography
                              color="#FFC302"
                              sx={{ fontWeight: "bold" }}
                            >
                              Borderline risk
                            </Typography>
                          )}

                          {data.result >= 7.5 && data.result <= 19.9 && (
                            <Typography
                              color="#FF8F00"
                              sx={{ fontWeight: "bold" }}
                            >
                              Intermediate risk
                            </Typography>
                          )}

                          {data.result >= 20 && (
                            <Typography color="red" sx={{ fontWeight: "bold" }}>
                              High risk
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ paddingTop: "1rem" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                window.location.reload();
                              }}
                              size="large"
                              type="submit"
                              sx={{ width: "95%" }}
                            >
                              Fill New form
                            </Button>

                            <Typography
                              sx={{ textDecoration: "underline" }}
                              variant="h7"
                              target={"_blank"}
                              marginTop={"0.6REM"}
                              component="a"
                              href="https://shc.gov.sa/Arabic/NHC/Activities/GuidelinesLibrary/Saudi%20Guideline%20for%20Dyslipidemia%20Management.pdf"
                            >
                              National Guideline For Dyslipidemia Management
                            </Typography>
                            <Typography
                              sx={{ textDecoration: "underline" }}
                              variant="h7"
                              target={"_blank"}
                              marginTop={"0.6REM"}
                              component="a"
                              href="https://shc.gov.sa/Arabic/Documents/NHCSHA%20Hypertension%20Guidelines.pdf"
                            >
                              National Hypertension Guideline
                            </Typography>

                            <Typography
                              sx={{ textDecoration: "underline" }}
                              variant="h7"
                              target={"_blank"}
                              marginTop={"0.6REM"}
                              component="a"
                              href="https://heartviews.org/viewimage.asp?img=HeartViews_2023_24_2_67_372458_f2.jpg"
                            >
                              See Treatment Goals
                            </Typography>
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                )}
              </>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  paddingInline: margin,
                  paddingTop: "5rem",
                  paddingBottom: "3rem",
                }}
              >
                <Card sx={{ p: "2rem" }}>
                  <CardHeader
                    subheader=" ----------------------------------------------------   "
                    title="Kindly fill the data of your patient below"
                  />
                  <CardContent sx={{ p: 0 }}>
                    <Box sx={{}}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                      >
                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Are you a healthcare practitioner
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="isHealthcarePractitioner"
                                  value={isHealthcarePractitioner}
                                  onChange={
                                    handleChangeisHealthcarePractitioner
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="isHealthcarePractitioner" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="healthcare_setting"
                                required
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                Healthcare Sector
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="healthcare_setting"
                                fullWidth
                                variant="outlined"
                                name="healthcare_setting"
                                select
                                SelectProps={{ native: true }}
                                helperText={
                                  <ErrorMessage name="healthcare_setting" />
                                }
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                              >
                                <option value="">Pick a choice</option>{" "}
                                {/* Add the default option */}
                                {states.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="region"
                                required
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                Region
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="region"
                                fullWidth
                                variant="outlined"
                                name="region"
                                select
                                SelectProps={{ native: true }}
                                helperText={<ErrorMessage name="region" />}
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                              >
                                <option value="">Pick a choice</option>{" "}
                                {/* Add the default option */}
                                {regions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="centerName-input"
                                required
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                Center Name
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="centerName-input"
                                fullWidth
                                variant="outlined"
                                name="centerName"
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                                helperText={<ErrorMessage name="centerName" />}
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Gender
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="gender"
                                  row
                                >
                                  <FormControlLabel
                                    value="Male"
                                    control={<Radio />}
                                    label="Male"
                                  />
                                  <FormControlLabel
                                    value="Female"
                                    control={<Radio />}
                                    label="Female"
                                  />
                                </RadioGroup>
                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="gender" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="age-input"
                                required
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                Age (40 - 99)
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="age-input"
                                fullWidth
                                helperText={<ErrorMessage name="age" />}
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                                variant="outlined"
                                name="age"
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="systolicBloodPressure-input"
                                required
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                Systolic Blood Pressure (90 – 200mmHg)
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="systolicBloodPressure-input"
                                fullWidth
                                variant="outlined"
                                name="systolicBloodPressure"
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                                helperText={
                                  <ErrorMessage name="systolicBloodPressure" />
                                }
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="diastolicBloodPressure-input"
                                required
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                Diastolic Blood Pressure (60 – 130mmHg)
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="diastolicBloodPressure-input"
                                fullWidth
                                variant="outlined"
                                name="diastolicBloodPressure"
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                                helperText={
                                  <ErrorMessage name="diastolicBloodPressure" />
                                }
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Smoker
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="smoker"
                                  value={smoker}
                                  onChange={handleChangeSmoker}
                                  row
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormControlLabel
                                    value="Current"
                                    control={<Radio />}
                                    label="Current"
                                  />

                                  <Tooltip
                                    title="Smokes every day or on some days."
                                    placement="top-start"
                                  >
                                    <Info
                                      sx={{
                                        width: tipWidth,
                                        color: "#2A9DF4",
                                        marginTop: tipMarginTop,
                                        marginInlineStart: tipMarinInlineStart,
                                        marginInlineEnd: tipMarginInlineEnd,
                                      }}
                                    />
                                  </Tooltip>

                                  <FormControlLabel
                                    value="Former"
                                    control={<Radio />}
                                    label="Former"
                                  />

                                  <Tooltip
                                    title="Does not currently smoke and has been abstinent for at least 7 days in a row."
                                    placement="top-start"
                                  >
                                    <Info
                                      sx={{
                                        width: tipWidth,
                                        color: "#2A9DF4",
                                        marginTop: tipMarginTop,
                                        marginInlineStart: tipMarinInlineStart,
                                        marginInlineEnd: tipMarginInlineEnd,
                                      }}
                                    />
                                  </Tooltip>

                                  <FormControlLabel
                                    value="Never"
                                    control={<Radio />}
                                    label="Never"
                                  />

                                  <Tooltip
                                    title="Has not smoked regularly and doesn't currently smoke."
                                    placement="top-start"
                                  >
                                    <Info
                                      sx={{
                                        width: tipWidth,
                                        color: "#2A9DF4",
                                        marginTop: tipMarginTop,
                                        marginInlineStart: tipMarinInlineStart,
                                      }}
                                    />
                                  </Tooltip>
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="smoker" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    On Hypertension Treatment ?
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="onHypertensiontreatment"
                                  value={onHypertensiontreatment}
                                  onChange={handleChangeOnHypertensiontreatment}
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="onHypertensiontreatment" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    On a Statin ?
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="onStatin"
                                  value={onStatin}
                                  onChange={handleChangeOnStatin}
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="onStatin" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    On Aspirin Therapy ?
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="onAspirinTherapy"
                                  value={onAspirinTherapy}
                                  onChange={handleChangeOnAspirinTherapy}
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="onAspirinTherapy" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    History of Diabetes ?
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="historyOfDiabetes"
                                  value={historyOfDiabetes}
                                  onChange={handleChangeHistoryOfDiabetes}
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="historyOfDiabetes" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Diabetes Mellitus with End-organ Damage
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="diabetesMellitusWithEndOrganDamage"
                                  value={diabetesMellitusWithEndOrganDamage}
                                  onChange={
                                    handleChangeDiabetesMellitusWithEndOrganDamage
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="diabetesMellitusWithEndOrganDamage" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="totalCholesterol-input"
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                Total Cholesterol in mg/dL (130 – 320)
                                <Typography
                                  component="span"
                                  variant="body1"
                                  sx={{ color: "red" }}
                                >
                                  *
                                </Typography>
                                <Tooltip
                                  title="To get from SI units (mmol/L) to mg/dL multiply by 38.67"
                                  placement="top-start"
                                >
                                  <Info
                                    sx={{
                                      color: "#2A9DF4",
                                      marginInlineStart: 1,
                                    }}
                                  />
                                </Tooltip>
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="totalCholesterol-input"
                                fullWidth
                                variant="outlined"
                                name="totalCholesterol"
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                                helperText={
                                  <ErrorMessage name="totalCholesterol" />
                                }
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="LDL-input"
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                LDL in mg/dL (30 – 300)
                                <Typography
                                  component="span"
                                  variant="body1"
                                  sx={{ color: "red" }}
                                >
                                  *
                                </Typography>
                                <Tooltip
                                  title="To get from SI units (mmol/L) to mg/dL multiply by 38.67"
                                  placement="top-start"
                                >
                                  <Info
                                    sx={{
                                      color: "#2A9DF4",
                                      marginInlineStart: 1,
                                    }}
                                  />
                                </Tooltip>
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="LDL-input"
                                fullWidth
                                variant="outlined"
                                name="LDL"
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                                helperText={<ErrorMessage name="LDL" />}
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="HDL-input"
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                HDL in mg/dL (20 – 100)
                                <Typography
                                  component="span"
                                  variant="body1"
                                  sx={{ color: "red" }}
                                >
                                  *
                                </Typography>
                                <Tooltip
                                  title="To get from SI units (mmol/L) to mg/dL multiply by 38.67"
                                  placement="top-start"
                                >
                                  <Info
                                    sx={{
                                      color: "#2A9DF4",
                                      marginInlineStart: 1,
                                    }}
                                  />
                                </Tooltip>
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="HDL-input"
                                fullWidth
                                variant="outlined"
                                name="HDL"
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                                helperText={<ErrorMessage name="HDL" />}
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Chronic Kidney Disease ?
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="chronicKidneyDisease"
                                  value={chronicKidneyDisease}
                                  onChange={handleChangeChronicKidneyDisease}
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Not available"
                                    control={<Radio />}
                                    label="Not Available"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="chronicKidneyDisease" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Family History of Premature Coronary Artery
                                    Disease ?
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="familyHistory"
                                  value={familyHistory}
                                  onChange={handleChangeFamilyHistory}
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Not available"
                                    control={<Radio />}
                                    label="Not Available"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="familyHistory" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Familial Hypercholesterolemia ?
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="familialHypercholesterolemia"
                                  value={familialHypercholesterolemia}
                                  onChange={
                                    handleChangeFamilialHypercholesterolemia
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Not available"
                                    control={<Radio />}
                                    label="Not Available"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                </RadioGroup>
                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="familialHypercholesterolemia" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Metabolic Syndrome ?
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="metabolicSyndrome"
                                  value={metabolicSyndrome}
                                  onChange={handleChangeMetabolicSyndrome}
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Not available"
                                    control={<Radio />}
                                    label="Not Available"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="metabolicSyndrome" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Elevated High-sensitivity C-reactive protein
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="elevatedHighSensitivity"
                                  value={elevatedHighSensitivity}
                                  onChange={handleChangeElevatedHighSensitivity}
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Not available"
                                    control={<Radio />}
                                    label="Not Available"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                </RadioGroup>
                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="elevatedHighSensitivity" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    History of premature menopause or
                                    pregnancy-associated conditions that
                                    increase the risk of atherosclerotic
                                    cardiovascular diseases
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="historyOfPrematureMenopause"
                                  value={historyOfPrematureMenopause}
                                  onChange={
                                    handleChangeHistoryOfPrematureMenopause
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Not available"
                                    control={<Radio />}
                                    label="Not Available"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="historyOfPrematureMenopause" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        {historyOfPrematureMenopause === "Yes" && (
                          <Grid item xs={12} md={12}>
                            <Card
                              style={{
                                border: "1px solid #EEEEEE",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <CardContent
                                style={{
                                  width: "100%",
                                  padding: paddingContent,
                                  paddingBottom: paddingBottom,
                                }}
                              >
                                <InputLabel
                                  htmlFor="historyOfPrematureMenopauseSpecification-input"
                                  required
                                  sx={{
                                    marginInlineStart: marginInlineStart,
                                    color: "black",
                                    "& .MuiInputLabel-asterisk": {
                                      color: "red",
                                    },
                                  }}
                                >
                                  Please Specify
                                </InputLabel>

                                <Field
                                  as={TextField}
                                  sx={{ paddingBottom: "0.7rem" }}
                                  id="historyOfPrematureMenopauseSpecification-input"
                                  fullWidth
                                  variant="outlined"
                                  name="historyOfPrematureMenopauseSpecification"
                                  FormHelperTextProps={{
                                    sx: {
                                      color: "red",
                                    },
                                  }}
                                  helperText={
                                    <ErrorMessage name="historyOfPrematureMenopauseSpecification" />
                                  }
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                        )}

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Chronic Inflammatory Condition
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="chronicInflammatoryCondition"
                                  value={chronicInflammatoryCondition}
                                  onChange={
                                    handleChangeChronicInflammatoryCondition
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Not available"
                                    control={<Radio />}
                                    label="Not Available"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="chronicInflammatoryCondition" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        {chronicInflammatoryCondition === "Yes" && (
                          <Grid item xs={12} md={12}>
                            <Card
                              style={{
                                border: "1px solid #EEEEEE",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <CardContent
                                style={{
                                  width: "100%",
                                  padding: paddingContent,
                                  paddingBottom: paddingBottom,
                                }}
                              >
                                <InputLabel
                                  htmlFor="chronicInflammatoryConditionSpecification-input"
                                  required
                                  sx={{
                                    marginInlineStart: marginInlineStart,
                                    color: "black",
                                    "& .MuiInputLabel-asterisk": {
                                      color: "red",
                                    },
                                  }}
                                >
                                  Please Specify
                                </InputLabel>

                                <Field
                                  as={TextField}
                                  sx={{ paddingBottom: "0.7rem" }}
                                  id="chronicInflammatoryConditionSpecification-input"
                                  fullWidth
                                  variant="outlined"
                                  name="chronicInflammatoryConditionSpecification"
                                  FormHelperTextProps={{
                                    sx: {
                                      color: "red",
                                    },
                                  }}
                                  helperText={
                                    <ErrorMessage name="chronicInflammatoryConditionSpecification" />
                                  }
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                        )}

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                marginRight: "auto",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <Field as={FormControl}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <Typography
                                    variant="inherit"
                                    component="span"
                                    color="black"
                                  >
                                    Elevated Coronary Artery Calcium Score
                                  </Typography>
                                  <Typography
                                    variant="inherit"
                                    sx={{
                                      marginInlineStart: marginInlineStart,
                                    }}
                                    component="span"
                                    color="error"
                                  >
                                    *
                                  </Typography>
                                </FormLabel>

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="elevatedCoronaryArteryCalciumScore"
                                  value={elevatedCoronaryArteryCalciumScore}
                                  onChange={
                                    handleChangeElevatedCoronaryArteryCalciumScore
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Not available"
                                    control={<Radio />}
                                    label="Not Available"
                                    sx={{
                                      marginLeft: lgUp ? "0" : "-1rem",
                                    }}
                                  />
                                </RadioGroup>

                                <FormHelperText sx={{ color: "red" }}>
                                  <ErrorMessage name="elevatedCoronaryArteryCalciumScore" />
                                </FormHelperText>
                              </Field>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Card
                            style={{
                              border: "1px solid #EEEEEE",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: paddingContent,
                                paddingBottom: paddingBottom,
                              }}
                            >
                              <InputLabel
                                htmlFor="coronaryArteryCalciumScore-input"
                                sx={{
                                  marginInlineStart: marginInlineStart,
                                  color: "black",
                                  "& .MuiInputLabel-asterisk": { color: "red" },
                                }}
                              >
                                Coronary Artery Calcium Score (0 -100)
                              </InputLabel>

                              <Field
                                as={TextField}
                                sx={{ paddingBottom: "0.7rem" }}
                                id="coronaryArteryCalciumScore-input"
                                fullWidth
                                variant="outlined"
                                name="coronaryArteryCalciumScore"
                                FormHelperTextProps={{
                                  sx: {
                                    color: "red",
                                  },
                                }}
                                helperText={
                                  <ErrorMessage name="coronaryArteryCalciumScore" />
                                }
                              />
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid xs={12} md={12}>
                          {loading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 3,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <CardActions sx={{ justifyContent: "space-between" }}>


                              <StyledTypography
                            variant={lgUp ? "h6" : "h7"}
                            component="div"
                            onClick={handleOpen2}
                          >
                            <ContactMail fontSize="large" />
                            CONTACT US
                          </StyledTypography>
                          <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                sx={{ width: "40%", fontSize: 15 }}
                              >
                                Submit
                              </Button>
                            </CardActions>
                          )}




                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )}
          </Form>
        )}
      </Formik>

      <Dialog
        open={open2}
        onClose={handleClose2}
        fullWidth
        sx={{ backdropFilter: "blur(5px)" }}
      >
        <Contact />
      </Dialog>
      {/* <Contact></Contact> */}
    </>
  );
};
