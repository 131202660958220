import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useMemo , useState} from "react";
import { themeSettings } from "./theme";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./scenes/dashboard/index";
import Login from "./scenes/login/index";
import Home from "scenes/home";
import NotFound from "scenes/notFound/404";
import { KFCForm } from "scenes/KFC/KFCForm";
import { showLogin } from "state/dashboard";
import ListAdmins from "scenes/dashboard/admins/allAdmins/ListOffices";
import Signup from "scenes/dashboard/admins/createAdmin/CreateOffice";
import UpdateAdmin from "scenes/dashboard/admins/updateAdmin/UpdateAdmin";


//css/bootstrap.css  fonts/font-awesome/css/font-awesome.css  css/style.css  css/nivo-lightbox/nivo-lightbox.css   css/nivo-lightbox/default.css
function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const RequireAuth = ({ children }) => {
    const dispatch = useDispatch()
  
    if (!sessionStorage.getItem('token')){
      dispatch(showLogin())
      return <Navigate to="/login" />
    }
  
    return children;
  }

  const [activeKey, setActiveKey] = useState(null);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<KFCForm /> } />
              <Route path="/home" element={<RequireAuth> <Home /> </RequireAuth>} />
              <Route path="/dashboard" element={<RequireAuth> <Dashboard /> </RequireAuth>} />
              <Route path="/createAdmin" element={<Signup />} />
              <Route path="/updateAdmin" element={<UpdateAdmin />} />
              <Route path="/admins" element={ <ListAdmins />} />
              <Route path="*" element={<NotFound></NotFound>} />
              
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;