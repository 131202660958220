import PropTypes from "prop-types";
import ArrowTopRightOnSquareIcon from "@heroicons/react/24/solid/ArrowTopRightOnSquareIcon";

import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Logo } from "components/logo";
import { items } from "./config";
import { SideNavItem } from "./side-nav-item";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { changeSideNav, showSideNav } from "state/side-nav-status";
import { useEffect } from "react";
import { useTranslation } from "utils/language";
import { useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

export const SideNav = (props) => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const navStatus = useSelector((state) => state.navbar);
  const dispatch = useDispatch();

  useEffect(() => {
    if (lgUp) {
      dispatch(showSideNav());
    }
  }, [lgUp]);
  const content = (
    navStatus.show?
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "17rem",
        backgroundColor: "#fff",
      }}
      maxWidth
    >
      <Box>
        {lgUp ? (
          <></>
        ) : (
          <Box sx={{ p: 2 }}>
            <Typography
              sx={{
                float: "right",
                display: "inline-flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                width: "2rem",
                height: "2rem",
                borderRadius: "50%",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                transition: "background-color 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                },
              }}
              onClick={() => {
                dispatch(changeSideNav());
              }}
            >
              <CloseIcon sx={{ fontSize: "1.5rem" }} />
            </Typography>
          </Box>
        )}
        <Box paddingTop={2} paddingLeft={3}>
          <Box
            sx={{
              display: "flex",
              height: 95,
              width: 100,
              marginBottom: "1rem",
            }}
          >
            <Logo />
          </Box>
        </Box>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        width={"17rem"}
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
        }}
      >
 <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: "none",
        p: 0,
        m: 0,
      }}
    >
      {items.map((item) => {
        if (item.children) {
          return (
            <SideNavItem
              key={item.title}
              title={item.title}
              path={item.path}
              icon={item.icon}
              children={item.children}
              nested={true}
            />
          );
        } else {
          return (
            <SideNavItem
              key={item.title}
              title={item.title}
              path={item.path}
              icon={item.icon}
            />
          );
        }
      })}
    </Stack>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        sx={{
          px: 2,
          py: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            mt: 2,
            mx: "auto",
            width: "160px",
            "& img": {
              width: "100%",
            },
          }}
        ></Box>
        <Button
          component="a"
          endIcon={
            <SvgIcon fontSize="small" marginX="1rem">
              <ArrowTopRightOnSquareIcon />
            </SvgIcon>
          }
          onClick={() => {
            sessionStorage.clear("token");
            navigate("/login");
          }}
          fullWidth
          sx={{ mt: 2, marginBottom: "2rem" }}
          variant="contained"
        >
          {"Logout"}
        </Button>
      </Box>
    </Box> : <></>
  );

  if (navStatus.show) {
    return (
      <Drawer
        anchor="left"
        open={navStatus.show}
        variant="permanent"
        PaperProps={
          navStatus.show
            ? { sx: { width: "18%", zIndex: 1 } }
            : { sx: { display: "none" } }
        }
      >
        {content}
      </Drawer>
    );
  } else {
    return (
      <MenuIcon
        sx={{
          color: "white",
          fontSize: "2.5rem",
          cursor: "pointer",
          position: "fixed",
          top: "1rem",
          left: "1rem",
          zIndex: 1,
          backgroundColor: fillColor,
          borderRadius: "20%",
        }}
        onClick={() => {
          dispatch(showSideNav());
        }}
      />
    );
  }
};
// <Button onClick={()=>{
//   dispatch(showSideNav())
// }}>aloo</Button>
SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
