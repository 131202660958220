import {
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  TextField,
  Box,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
const ContactBox = styled("div")(({ theme }) => ({
  display: "stretch",
  alignItems: "center",
  overflow: "hidden",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0),
  marginBottom: theme.spacing(2),
}));

const ContactInput = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flexGrow: 1,
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    "&.Mui-focused": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}33`,
    },
  },
}));

const ContactIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.contrastText,
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const ContactCard = styled(Card)(({ theme }) => ({
  position: "flex",
  top: theme.spacing("10rem"),
  padding: "1rem",
  borderRadius: "2rem",
  zIndex: theme.zIndex.drawer + 1,
  animation: "fadeInRight 1s",
  "@keyframes fadeInRight": {
    "0%": {
      opacity: 0,
      transform: "translateX(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
}));

const ContactCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  borderRadius: "2rem",
  // padding: theme.spacing(2),
}));

const Contact = () => {
  const [question, setQuestion] = useState("");
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSendClick = () => {
    window.location.href = `mailto:nhc@shc.gov.sa?subject=Email from NHC-ASCVD-website. &body=${question}`;
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#ddd",
        float: "center",
        overflow: "hidden",
        borderRadius: "2rem",
      }}
    >
      <ContactCard>
        <ContactCardContent>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom>
            Kindly fill the box below
          </Typography>

          <ContactInput
            label="Your Question"
            // variant="filled"
            value={question}
            onChange={handleQuestionChange}
            multiline
            rows={4}
          />
          <ContactBox
            sx={{
              textAlign: "right",
              alignContent: "right",
              marginLeft: lgUp ? "20rem" : "6rem",
              marginTop:"1rem"
            }}
          >
            <ContactIconButton fullWidth onClick={handleSendClick}>
              <Typography variant="caption">Send us</Typography>
            </ContactIconButton>
          </ContactBox>
        </ContactCardContent>
      </ContactCard>
    </Box>
  );
};

export default Contact;
