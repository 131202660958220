import { useTheme } from '@mui/material/styles';

export const Logo = () => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  return (
    <>
      <img src="/img/logo2.png" alt="Logo" style={{ backgroundColor: 'transparent' }} />

    </>
  );
};