import { useCallback, useMemo, useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FormsTable } from "./forms-table";
import axios from "axios";
import { downloadExcel, getFormsUrl, getOfficseUrl } from "utils/constants";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { debounce } from "lodash";
import LoadingAnimation from "components/lottieAnimations/loadingAnimation";
import { DownloadOutlined } from "@mui/icons-material";
import { RefreshOutlined } from "@mui/icons-material";
const ListForms = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const handleSearch = debounce((event) => {
    setSearchQuery(event.target.value);
  }, 500);
  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      axios
        .get(getFormsUrl, {
          params: {
            page: page,
            limit: rowsPerPage,
            query: searchQuery,
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setData(res.data.data);
          setCount(res.data.total);
          setLoading(false);
        });
    };
    fetchData();
  }, [page, rowsPerPage, searchQuery, refresh]);

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);
  const theme = useTheme();
  if (loading) {
    return <LoadingAnimation animationNumber={1} />;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          py: 2,
          width: "82.5%",
          float: "right",
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <div>
                <Card sx={{ width: "200%" }}>
                  <OutlinedInput
                    defaultValue=""
                    fullWidth
                    onChange={handleSearch}
                    placeholder="Search forms with Center Name or Healcare Setting"
                    startAdornment={
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <MagnifyingGlassIcon />
                        </SvgIcon>
                      </InputAdornment>
                    }
                  />
                </Card>
              </div>
              <Stack
                direction={"row"}
                justifyContent={"end"}
                alignItems={"center"}
                spacing={1}
              >
                {" "}
                <Button
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    height: "2.2rem",
                    "&:hover": {
                      backgroundColor: theme.palette.info.main,
                    },
                  }}
                  onClick={()=> {
                    axios({
                      url: downloadExcel,
                      method: 'GET',
                      headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                      },
                      responseType: 'blob', // receive data in binary format
                    })
                      .then(response => {
                        // create a blob URL from the binary data
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                    
                        // create a link element to trigger the download
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'submitted-forms.xlsx');
                        document.body.appendChild(link);
                    
                        // simulate a click on the link to trigger the download
                        link.click();
                      })
                      .catch(error => {
                        console.error(error);
                      });
              }}
                >
                  <DownloadOutlined />
                </Button>
                <Button
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    height: "2.2rem","&:hover": {
                      backgroundColor: theme.palette.info.main,
                    },
                  }}
                  onClick={r =>{
                    setRefresh((refresh + 1))
                  }}
                >
                  <RefreshOutlined />
                </Button>
              </Stack>
            </Stack>
            <FormsTable
              count={count}
              items={data}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};
export default ListForms;
