import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import { getRandomAvatar } from "utils/generate-avatar";
import { showEdit } from "state/dashboard";
import { useDispatch } from "react-redux";
import axios from "axios";
import { deleteRecordUrl } from "utils/constants";

const getInitials = (name = "") =>
  name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");

export const FormsTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
  } = props;
  const dispatch = useDispatch();
  const deleteItem = (id) => {
    axios
      .post(deleteRecordUrl, id, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.code === 201) {
          items = items.filter((item) => item.id !== id);
        }
      });
  };
  return (
    <Card>
        <Box  sx={{ minWidth: "78rem", minHeight: "78.6vh", overflowX: "auto" }}>
        <Table
          sx={{ "& tbody": { "& tr": { height: "auto" } }, overflow: "auto" }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Healthcare_Setting</TableCell>
              <TableCell>Center_Name</TableCell>
              <TableCell>region</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Blood_Pressure (mmHg)</TableCell>
              <TableCell>Smoker</TableCell>
              <TableCell>On_Hypertension_Treatment</TableCell>
              <TableCell>On Statin</TableCell>
              <TableCell>On_Aspirin_Therapy</TableCell>
              <TableCell>History_of_Diabetes</TableCell>
              <TableCell>Diabetes_with End_Organ_Damage</TableCell>
              <TableCell>Total Cholesterol</TableCell>
              <TableCell>LDL</TableCell>
              <TableCell>HDL</TableCell>
              <TableCell>Chronic Kidney_Disease</TableCell>
              <TableCell>Family History</TableCell>
              <TableCell>Familial Hypercholesterolemia</TableCell>
              <TableCell>Metabolic_Syndrome</TableCell>
              <TableCell>Elevated_High Sensitivity</TableCell>
              <TableCell>History of Premature_Menopause</TableCell>
              <TableCell>Chronic Inflammatory_Condition</TableCell>
              <TableCell>Elevated_Coronary Artery_Calcium_Score</TableCell>
              <TableCell>Coronary_Artery Calcium_Score</TableCell>
              <TableCell>Risk</TableCell>
              <TableCell>Created_At</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((record) => {
              console.log(record);
              return (
                <TableRow hover key={record.id}>
                  <TableCell>{record.healthcare_setting}</TableCell>
                  <TableCell>{record.centerName}</TableCell>
                  <TableCell>{record.region}</TableCell>
                  <TableCell>{record.gender}</TableCell>
                  <TableCell>{record.age}</TableCell>
                  <TableCell>
                    {record.systolicBloodPressure}/
                    {record.diastolicBloodPressure}
                  </TableCell>
                  <TableCell>{record.smoker}</TableCell>
                  <TableCell>
                    {record.onHypertensiontreatment === 1 ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>{record.onStatin === 1 ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    {record.onAspirinTherapy === 1 ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>
                    {record.historyOfDiabetes === 1 ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>
                    {record.diabetesMellitusWithEndOrganDamage === 1
                      ? "Yes"
                      : "No"}
                  </TableCell>
                  <TableCell>{record.totalCholesterol}</TableCell>
                  <TableCell>{record.LDL}</TableCell>
                  <TableCell>{record.HDL}</TableCell>
                  <TableCell>{record.chronicKidneyDisease}</TableCell>
                  <TableCell>{record.familyHistory}</TableCell>
                  <TableCell>{record.familialHypercholesterolemia}</TableCell>
                  <TableCell>{record.metabolicSyndrome}</TableCell>
                  <TableCell>{record.elevatedHighSensitivity}</TableCell>
                  <TableCell>{record.historyOfPrematureMenopause}</TableCell>
                  <TableCell>{record.chronicInflammatoryCondition}</TableCell>
                  <TableCell>
                    {record.elevatedCoronaryArteryCalciumScore}
                  </TableCell>
                  <TableCell>{record.coronaryArteryCalciumScore}</TableCell>
                  <TableCell>{record.result}</TableCell>

                  <TableCell>
                    {new Date(record.created_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => deleteItem(record.id)}
                      style={{ backgroundColor: "red", color: "white" }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

FormsTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isHealthcarePractitioner: PropTypes.number.isRequired,
      healthcare_setting: PropTypes.string.isRequired,
      centerName: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      age: PropTypes.number.isRequired,
      systolicBloodPressure: PropTypes.number.isRequired,
      diastolicBloodPressure: PropTypes.number.isRequired,
      smoker: PropTypes.string.isRequired,
      onHypertensiontreatment: PropTypes.number.isRequired,
      onStatin: PropTypes.number.isRequired,
      onAspirinTherapy: PropTypes.number.isRequired,
      historyOfDiabetes: PropTypes.number.isRequired,
      diabetesMellitusWithEndOrganDamage: PropTypes.number.isRequired,
      totalCholesterol: PropTypes.number.isRequired,
      LDL: PropTypes.number.isRequired,
      HDL: PropTypes.number.isRequired,
      chronicKidneyDisease: PropTypes.string.isRequired,
      familyHistory: PropTypes.string.isRequired,
      familialHypercholesterolemia: PropTypes.string.isRequired,
      metabolicSyndrome: PropTypes.string.isRequired,
      elevatedHighSensitivity: PropTypes.string.isRequired,
      historyOfPrematureMenopause: PropTypes.string.isRequired,
      historyOfPrematureMenopauseSpecification: PropTypes.string,
      chronicInflammatoryCondition: PropTypes.string.isRequired,
      chronicInflammatoryConditionSpecification: PropTypes.string,
      elevatedCoronaryArteryCalciumScore: PropTypes.string.isRequired,
      coronaryArteryCalciumScore: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ),
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};
