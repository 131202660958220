import React from "react";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import {
  Alert,
  Button,
  Stack,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { useState } from "react";
import { schema as validationSchema } from "utils/validation-schema";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showEdit, showManageTiers } from "state/dashboard";
import { data } from "jquery";
import CorrectAnimation from "components/lottieAnimations/CorrectAnimation";
import CorrectAnimationReceipt from "components/lottieAnimations/CorrectAnimationReceipt";
import { createAdminUrl } from "utils/constants";

const Signup = () => {
  useMediaQuery("(min-width: 600px)");
  // const office = useSelector((state) => state.office).office;

  useEffect(() => {
    // if (office.role !== "Admin") {
    //   setMessage("Only admins can craete offices.");
    //   setOpen(true);
    // }
  }, []);
  const formatCurrency = (value) => {
    if (!value) return "";
    return `${value.toFixed(2)}`;
  };
  const [data, setData] = useState(null);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      submit: null
    },
    validationSchema: validationSchema,

    onSubmit: async (values, helpers) => {
      try {

        axios
          .post(createAdminUrl, values, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setData(response.data.data)
            }
          })
          .catch((error) => {
            console.log(error)
            const validationErrors = error.response.data.errors;
            helpers.setErrors(validationErrors);
          });
      } catch (err) {
        console.log(err)
        
      }
    },
  });

  return (
    <>
      
      {data && 
        <CorrectAnimationReceipt onComplete={()=>{
          window.location.reload();
        }}></CorrectAnimationReceipt>
      }
      <div>
        <Box
          sx={{
            backgroundColor: "background.paper",
            float: "right",
            width: "82.5%",
            flex: "1 1 auto",
            alignItems: "start",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth:"45rem",
              width: "100%",
              py:"7rem"
            }}
          >
            <div>
              <Stack
                spacing={1}
                sx={{
                  mb: 3,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h4">Add New Admin</Typography>
                    
              </Stack>
              {}
              {
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Stack spacing={2}>

                    <div style={{ display: "flex", gap: "1rem" }}>

                      <TextField
                        error={!!(formik.touched.name && formik.errors.name)}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        label="Name"
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                      />
                    </div>
                    
                    <div style={{ display: "flex", gap: "1rem" }}>

                    <TextField
                      error={!!(formik.touched.email && formik.errors.email)}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      label="Email Address"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      value={formik.values.email}
                    />
                  </div>

                  <div style={{ display: "flex", gap: "1rem" }}>

                    <TextField
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      label="Password"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.password}
                    />

                    <TextField
                      error={
                        !!(formik.touched.password_confirmation && formik.errors.password_confirmation)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password_confirmation && formik.errors.password_confirmation
                      }
                      label="Password Confirmation"
                      name="password_confirmation"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.password_confirmation}
                    />

                  </div>
                    <Divider></Divider>
                   
                  </Stack>

                  {formik.errors.submit && (
                    <Typography color="error" sx={{ mt: 3 }} variant="body2">
                      {formik.errors.submit}
                    </Typography>
                  )}
                  <Button
                    fullWidth
                    size="large"
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                  >
                    Create
                  </Button>

                  <Alert color="primary" severity="info" sx={{ mt: 3 }}></Alert>
                </form>
              }
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Signup;
