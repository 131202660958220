import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { Dialog, useMediaQuery } from "@mui/material";
import Contact from "scenes/dashboard/contact-us/contact-us";
function ResponsiveAppBar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const margin = lgUp ? "12rem" : "0rem";

  const pages = [
    { name: "Login", route: "/login" },
    { name: "Contact us", route: "/imported" },
  ];

  // if(lgUp)
  // return (
  //   <AppBar position="sticky" color="default" sticky="top">
  //     <Container maxWidth="xl" backgroundColor={theme.palette.grey}>
  //       <Toolbar disableGutters backgroundColor={theme.palette.grey}>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //             width: "100%",
  //           }}
  //         >
  //           <Box sx={{ flex: 1, marginLeft:"4rem" , marginRight:"4rem" }}>
  //             <Typography maxWidth sx={{ lineHeight: "1.5" }}>
  //               This interactive tool provides a user-friendly interface for robust estimation of Atherosclerotic Cardiovascular Disease (ASCVD) risk in the KSA population. It represents a collaborative initiative and unifies the efforts to combat cardiovascular disease and improve patient outcomes in the Kingdom.
  //             </Typography>
  //           </Box>
  //           <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
  //             <img src="../../img/app-logo.png" alt="Logo" width="50%" />
  //           </Box>
  //         </Box>
  //       </Toolbar>
  //     </Container>
  //   </AppBar>
  // );
  // else
  return (
    <>
    <AppBar
      position="static"
      color="default"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginTop: lgUp ? "1rem" : "0.5rem",
      }}
    >
      {" "}
      <img
        src="../../img/app-logo.png"
        alt="Logo"
        width={lgUp ? "30%" : "70%"}
        style={{
          marginBottom: lgUp ? "1rem" : "0.5rem",
        }}
      />
      <Box
        sx={{
          paddingInline: margin,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: lgUp?"0 4rem" : "1rem",
          padding: lgUp? "2rem" : "1rem",
          background: "linear-gradient(to right, #652d91, #00a89e)",
          borderRadius: "1rem",
        }}
      >
        <Typography
          variant={lgUp ? "h4" : "h5"}
          sx={{ textAlign: "center" }}
          color="white"
        >
          National ASCVD Risk Assessment Calculator in KSA
        </Typography>
        <Typography
          maxWidth
          variant={lgUp ? "h6" : "h7"}
          sx={{
            textAlign: lgUp ? "center" : "justify",
            paddingInline: lgUp ? "5rem" : "0rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          color="white"
        >
          This interactive tool provides a user-friendly interface for robust
          estimation of Atherosclerotic Cardiovascular Disease (ASCVD) risk in
          the KSA population. It represents a collaborative initiative and
          unifies the efforts to combat cardiovascular disease and improve
          patient outcomes in the Kingdom.
        </Typography>


      </Box>

    </AppBar>

</>
    // <AppBar position="sticky" color="default" sticky="top" sx={{background: "linear-gradient(to right, #652d91, #00a89e)",}}>
    //   <Container maxWidth="xl" backgroundColor={theme.palette.grey}>
    //     <Toolbar disableGutters backgroundColor={theme.palette.grey}>

    //         <img src="../../img/app-logo.png" alt="Logo" width="20%" />

    //       <Box
    //         sx={{
    //           flexGrow: 1,
    //           display: { xs: "none", md: "flex" },
    //           justifyContent: "flex-end",
    //         }}
    //       >
    //         {pages.map((page) => (
    //           <Button
    //             key={page.name}
    //             onClick={() => navigate(page.route)}
    //             sx={{color: "white", display: "block" }}
    //           >
    //             {page.name}
    //           </Button>
    //         ))}
    //       </Box>
    //     </Toolbar>
    //   </Container>
    // </AppBar>
  );
}
export default ResponsiveAppBar;
