import React, { useEffect, useState } from "react";
import { SideNav } from "./side-nav";
import { useSelector } from "react-redux";
import Overview from "./overview";
import { Box } from "@mui/material";
import ListForms from "./forms/forms-history";
import { KFCForm } from "scenes/KFC/KFCForm";
import Contact from "./contact-us/contact-us";
import ListAdmins from "./admins/allAdmins/ListOffices";
import Signup from "./admins/createAdmin/CreateOffice";
import UpdateAdmin from "scenes/dashboard/admins/updateAdmin/UpdateAdmin";

const Dashboard = () => {
  const componentToShow = useSelector((state) => state.dashboard);

  let componentToRender;
  const [userManagementState, setUserManagementState] = useState("admins");

  useEffect(() => {
    if (componentToShow.componentToShow === "user-management") {
      setUserManagementState("admins");
    }
  }, [componentToShow]);

  switch (componentToShow.componentToShow) {
    case "forms":
      componentToRender = <ListForms />;
      break;
    case "contact":
      componentToRender =<Box sx={{
        backgroundColor:"#e1e1e1",
        width:"80%",
        height:"100vh",
        float:"right",
        alignContent:"center",
        alignItems:"center",
        justifyContent:"center",
        justifyItems:"center",
      }}> <Box
      sx={{
        marginTop:"6rem",
        marginBottom:"2rem",
        marginInline:"6rem"
    }}
      ><Contact /></Box></Box>;
      break;
      case "edit":
        componentToRender = <UpdateAdmin />
        break;
    case "user-management":
      switch (userManagementState) {
        case "createAdmin":
          componentToRender = <Signup></Signup>;
          break;

        case "updateAdmin":
          componentToRender = (
            <UpdateAdmin setViewState={setUserManagementState} />
          );
          break;

        case "admins":
          componentToRender = (
            <ListAdmins setViewState={setUserManagementState} />
          );
          break;
      }

      break;
    case "fill":
      componentToRender = (
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#ddd",
            width: "82.5%",
            float: "right",
          }}
        >
          <KFCForm admin={true} />
        </Box>
      );
      break;
    default:
      componentToRender = <Overview />;
      break;
  }

  return (
    <Box sx={{ backgroundColor: "#000" }}>
      <SideNav />
      {componentToRender}
    </Box>
  );
};

export default Dashboard;
