import * as Yup from "yup";


export const schema = Yup.object({
  name: Yup.string().max(255).required("Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
   
  password: Yup.string()
    .min(8)
    .required("Passowrd is required"),
    
  password_confirmation: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
  .required('Password confirmation is required'),
});

export const updateSchema = Yup.object({
  name: Yup.string().max(255).required("Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().test('password', 'Password must be at least 8 characters', (value) => {
    if (value && value.length > 0) {
      return value.length >= 8;
    }
    return true; // Allow nullable password
  }),
  password_confirmation: Yup.string().test('password_confirmation', 'Passwords must match', function (value) {
    const { password } = this.parent;
    if (password && password.length > 0) {
      return value === password;
    }
    return true; // Allow nullable password_confirmation
  }),
});
