import PropTypes from "prop-types";
// NextLink from 'next/link';
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  useMediaQuery,
} from "@mui/material";
//import { Logo } from 'src/components/logo';
// TODO: Change subtitle text

export const Layout = (props) => {
  const { children } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flex: "1 1 auto",
        height: "100%",
      }}
    >
      <Grid container sx={{ flex: "1 1 auto" }}>
        <Grid
          xs={lgUp ? 6 : 12}
          lg={lgUp ? 6 : 12}
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            component="header"
            sx={{
              left: 0,
              position: "fixed",
              top: 0,
              width: "100%",
              alignItems:"center"
            }}
          >
          </Box>
          {children}
        </Grid>

        {lgUp && (
          <Grid
            xs={5.9}
            lg={5.9}
            sx={{
              height:"96%",
              marginTop:"1%",
              borderRadius:"1rem",
              alignItems: "center",
              background: "linear-gradient(to right, #652d91, #00a89e)",
              color: "white",
              display: "flex",
              paddingInline:"4rem",
              justifyContent: "center",
              "& img": {
                maxWidth: "100%",
              },
            }}
          >
            <Box sx={{ p: 3 }}>
              <Typography
                align="center"
                color="inherit"
                sx={{
                  fontSize: "24px",
                  lineHeight: "32px",
                  mb: 1,
                }}
                variant="h1"
              >
                Welcome to{" "}
                <Box component="a" sx={{ color: "#fff" }} target="_blank">
                  National ASCVD Risk Assessment Calculator in KSA
                </Box>
              </Typography>
              <Typography align="center" sx={{ mb: 3 }} variant="subtitle1">
                This interactive tool provides a user-friendly interface for
                robust estimation of Atherosclerotic Cardiovascular Disease
                (ASCVD) risk in the KSA population. It represents a
                collaborative initiative and unifies the efforts to combat
                cardiovascular disease and improve patient outcomes in the
                Kingdom.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Layout.prototypes = {
  children: PropTypes.node,
};
