import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';

const NotFound = () => (
  <>
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        marginTop:"8rem",
        height:'32rem',
        float:'bottom'
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              mb: 3,
              textAlign: 'center'
            }}
          >
            <img
              alt="404 Not Found"
              src="/5203298.png"
              style={{
                display: 'inline-block',
                maxWidth: '85%',
              }}
            />
          </Box>
          <Typography
            align="center"
            sx={{ mb: 3 }}
            variant="h3"
          >
            الصفحة التي تبحث عنها غير موجودة.
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            variant="body1"
          >
            إما أنك حاولت استخدام طريق غير مشروع أو وصلت هنا عن طريق الخطأ.
أياً كان السبب، حاول استخدام التصفح للتنقل.
          </Typography>
          <Button
            href="/"
            startIcon={(
              <SvgIcon fontSize="small">
                <ArrowLeftIcon />
              </SvgIcon>
            )}
            sx={{ mt: 3 }}
            variant="contained"
          >
           العودة للصفحة الرئيسية
          </Button>
        </Box>
      </Container>
    </Box>
  </>
);

export default NotFound;
