import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./state";
import dashboardReducer from "state/dashboard";
import NavbarReducer from "state/side-nav-status";
import { Provider } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import LanguageReducer from "state/language";


const store = configureStore({
  reducer: {
    global: globalReducer,
    dashboard: dashboardReducer,
    navbar: NavbarReducer,
    lang:LanguageReducer ,
  },
});

setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);