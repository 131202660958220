const initialState = {
  componentToShow: "login",
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_FORMS":
      return { ...state, componentToShow: "forms" };
    case "SHOW_OVERVIEW":
      return { ...state, componentToShow: "overview" };
    case "SHOW_FILL":
      return { ...state, componentToShow: "fill" };
    case "SHOW_USER_MANAGEMENT":
      return { ...state, componentToShow: "user-management" };
    case "SHOW_EDIT":
      return { ...state, componentToShow: "edit", admin: action.payload };
      case "SHOW_LOGIN":
        return { ...state, componentToShow: "login" };
        case "SHOW_CONTACT":
          return { ...state, componentToShow: "contact" };
      default:
      return state;
  }
}

// actions.js
export const showLogin = () => ({ type: "SHOW_LOGIN" });
export const showEdit = (payload) => ({ type: "SHOW_EDIT", payload });
export const showOverview = () => ({ type: "SHOW_OVERVIEW" });
export const showUserManagament = () => ({ type: "SHOW_USER_MANAGEMENT" });
export const showFill = () => ({ type: "SHOW_FILL" });
export const showContact = () => ({ type: "SHOW_CONTACT" });
export const showForms = () => ({type:"SHOW_FORMS"})
