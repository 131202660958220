import { useCallback, useMemo, useState, useEffect } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { OfficesTable } from "./offices-table";
import axios from "axios";
import { getAdmins, getOfficseUrl } from "utils/constants";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { debounce } from 'lodash';
import { useSelector } from "react-redux";
import { Contact } from "components/landing/contact";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

const ListAdmins = ({setViewState }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [count , setCount] = useState(0)

  const handleAddAdminClick = () => {
    setViewState("createAdmin");
  };

  const handleSearch = debounce((event) => {
    setSearchQuery(event.target.value);
  }, 500);

  useEffect(
    ()=>{
      setPage(0)
    }
  , [rowsPerPage])

  useEffect(() => {

    try{

      axios.get(getAdmins , {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response.data.data)
          setLoading(false)

          if (response.status === 200) {
            setData(response.data.data)
          }

        })
        .catch((error) => {
          console.log(error)
          setLoading(false);
          setError(true)
      });
    } catch (err) {
      console.log(err)
      setError(true)
    }
    
    
  }, []);


  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);



  return (
    <>
      <Box
        component="main"
        sx={{
          py: 2,
          width: "82.5%",
          float: "right",
          backgroundColor:"#e1e1e1"
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack  direction="row" justifyContent="space-between" alignItems="center">
             
            <Card 
            sx={{width:"25rem" , }}
            >
                  <OutlinedInput
                    defaultValue=""
                    onChange={handleSearch}
                    fullWidth
                    placeholder="Search admins with id"
                    startAdornment={
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <MagnifyingGlassIcon />
                        </SvgIcon>
                      </InputAdornment>
                    }
                  />
                </Card>
             

                <Button onClick={handleAddAdminClick} variant="contained" sx={{ paddingLeft:"0.7rem",width:"6rem" , height:"2.5rem"}}>
                  <PlusIcon/>   Admin
                </Button>



                
            </Stack>
            <OfficesTable
              count={count}
              items={data}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};
export default ListAdmins;  
