const initialState = {
    show: true,
  };
  
  export default function NavbarReducer(state = initialState, action) {
    switch (action.type) {
      case "CHANGE":
        return { ...state, show: !state.show };
  
        case "SHOW":
          return { ...state, show: true };
    
      default:
        return state;
    }
  }
  
  // actions.js
  export const changeSideNav = () => ({ type: "CHANGE" });
  export const showSideNav = () => ({ type: "SHOW" });