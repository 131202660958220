import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { getRandomAvatar } from "utils/generate-avatar";
import { showEdit } from "state/dashboard";
import { useDispatch } from "react-redux";
import axios from "axios";
import CorrectAnimationReceipt from "components/lottieAnimations/CorrectAnimationReceipt";
import { useNavigate } from "react-router-dom";
import { deleteAdminUrl } from "utils/constants";

export const OfficesTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
  } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleOpen = (id) => {
    setSelectedAdmin(id);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setSelectedAdmin(null);
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  const deleteOffice = () => {
    setLoading(true);
    try {
      axios
        .get(deleteAdminUrl + selectedAdmin, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response.data.errors);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        disableBackdropClick
      >
        <DialogTitle>Deleting Admin</DialogTitle>

        <DialogContent>
          <Typography>Are you sure to delete this admin ?</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Back
          </Button>

          {loading == true ? (
            <CircularProgress
              color="primary"
              size={30}
              sx={{ marginInline: "9%" }}
            />
          ) : (
            <Button onClick={deleteOffice} color="primary">
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Card>
        <Box  sx={{ minWidth: "78rem", minHeight: "78.6vh", overflowX: "auto" }}>
          <Table
            sx={{ "& tbody": { "& tr": { height: "auto" } } , overflowX:"auto"}}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: "4.65rem" }}>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Signed Up</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((office, index) => {
                return (
                  <TableRow hover key={office.id}>
                    {/* <TableCell>{(page * rowsPerPage) + index + 1}</TableCell> */}

                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={2}>
                        {<Avatar src={getRandomAvatar()}></Avatar>}
                        <Typography variant="subtitle2">
                          {office.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{office.email}</TableCell>

                    <TableCell>
                      {new Date(office.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      })}
                    </TableCell>
                    <TableCell>
                      <Stack
                        direction={"row"}
                        spacing={"1rem"}
                        justifyContent={"end"}
                        marginRight={"2rem"}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            const data = {
                              id: office.id,
                              email: office.email,
                              name: office.name,
                              password: office.password,
                            };
                            dispatch(showEdit(data))
                            // navigate("/updateAdmin", { state: data });
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            marginLeft: "5%",
                            "&:hover": {
                              backgroundColor: "#dd2000",
                            },
                          }}
                          onClick={() => {
                            handleOpen(office.id);
                          }}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </Box>
  );
};

OfficesTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
};
